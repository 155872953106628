import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  /* background-color: ${(props) =>
    props.bgColor ? props.bgColor : "#000000"}; */
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`;
const P = styled.p`
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: #fff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    /* font-size: 14px; */
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const Heading = styled.div`
  font-family: "Acadian";
  /* padding-top:50px; */
  /* padding-bottom: 50px; */
  text-shadow: 2px 2px 10px rgb(199 6 0);
  font-size: 70px;
  margin: 0;
  font-weight: 200;
  /* background-image: linear-gradient(to top, #dee6ff, #4e78ff); */
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: #F8D700;
  @media (max-width: 1199px) {
    font-size: 50px;
  }
  @media (max-width: 599px) {
    font-size: 40px;
  }
`;
const SectionHeading = styled.h2`
  /* background-image: linear-gradient(to top, #4e78ff, #003cff); */
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgb(248,215,0,100%);
  font-size: 70px;
  font-family: "Acadian";
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  line-height: 80px;
  text-shadow: 2px 2px 10px rgb(199 6 0);
  margin: 0;
  padding: 0;
  font-weight: 200;
  margin-top: ${(props) => (props.m ? props.m : "")};
  @media (max-width: 1199px) {
    font-size: 50px;
  }
  @media (max-width: 599px) {
    font-size: 40px;
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "Norse";
  font-size: ${(props) => (props.fs ? props.fs : "65px")};
  /* color: #F8D700; */
  padding: ${(props) => (props.p ? props.p : "70px 0 0px 0")};
  font-weight: 200;
  color: #ffffff;
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "50px")};
  }
  
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-Medium";
  font-size: ${(props) => (props.fs ? props.fs : "30px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */
  text-shadow: 2px 2px 10px rgb(199 6 0);
    color: ${(props) => (props.color ? props.color : "#FCCB00")};
  padding: 0;
  margin: 0;
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;

export {
  SectionWrapper,
  P,
  SectionHeading,
  SectionSubHeading,
  CustomContainer,
  SectionSmallHeading,
  Heading,
};
