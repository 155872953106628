import React, { useEffect } from "react";
import Menu from "./menu";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

import Aos from "aos";
import "aos/dist/aos.css";
const Background = styled.div`
  /* background: url("/images/bg.png"); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */
  min-height: 70vh;
  display: flex;
  align-items: center;
  background-color: #000000;
  @media screen and (width: 768px) {
    padding: 75px 0 0 0;
  }
   @media screen and (max-width: 499px) {
    min-height: 57vh; 
    padding-top: 79px
   }
`;
const IntroImage = styled(Grid)`
  /* padding: 50px 0 0 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
  @media screen and (max-width: 499px) {
    // padding-top: 79px
   }
`;
const Div = styled.div``;
const HeaderImage = styled.img`
   width: 100%;
   max-height: 200px;
   max-width: 800px;
`;

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  return (
    <div>
      <Menu />
      <Background>
        <Container maxWidth="xl">
          <Grid container>
            {/* <Intro xs={12} md={5} p599="30px 0 0 0">
              <Div
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in"
                // data-aos-delay="500"
                // data-aos-offset="0"
                data-aos-duration="1500"
              >
                <SectionHeading>Fafnir</SectionHeading>
                <Heading>A Son Of Hreidmar</Heading>
                <P maxWidth="80%" p="0px 0px 30px 0">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magnn.
                </P> */}
                {/* <ReadMore
                  border="none"
                  href="https://app.uniswap.org/#/swap?outputCurrency=0xd111C8516BAFED13fB2F50c2fdf4031C181Cdc76&chain=mainnet"
                  target="_blank"
                >
                  Buy Now
                </ReadMore> */}
                {/* <ReadMore
                  color="#fff"
                  bgcolor="#000"
                  href="https://www.dextools.io/app/ether/pair-explorer/0xd464b607f64093ce8fb6b68d34e9992c01eef059"
                  target="_blank"
                >
                  Read More
                </ReadMore>
              </Div>
            </Intro> */}
            <IntroImage
              xs={12}
              md={12}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              // data-aos-delay="500"
              // data-aos-offset="0"
              data-aos-duration="1500"
            >
              <HeaderImage
                src="/images/ape.png"
              />
            </IntroImage>
          </Grid>
        </Container>
      </Background>
    </div>
  );
};

export default Index;
