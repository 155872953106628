import './App.css';
import React from "react";
import S1_Header from "./pages/MainPage/S1_Header";
import S2_About from "./pages/MainPage/S2_About";
import S2_Jadenomics from "./pages/MainPage/S2_Tokenomics";
import S5_Footer from "./pages//MainPage/S5_Footer";
import Ticker from "./pages/MainPage/S3_Ticker";

function App() {
  return (
    <div >
      <S1_Header />
      <S2_About />
      <Ticker />
      <S2_Jadenomics />
      {/* <S4_Community /> */}
      <S5_Footer />
    </div>
  );
}

export default App;
