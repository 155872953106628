import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { SectionWrapper } from "../../../styles";

const TickerPage = styled.div`
  transform: rotate(0deg) scale(0.98) translateX(5px);
  width: calc(100% + 50px);
`;
const TickerWrapper = styled.section`
  overflow: hidden;
  /* background-color: #ffffff; */
`;
const AnimateRightLeft = keyframes`
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
`;
const AnimateRightLeft2 = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-30%);
    }
`;
const TickerScroller = styled.div`
  display: flex;
  width: 100%;
  /* box-shadow: 0 8px 16px rgba(#000000, 0.5); */
  /* border-top: 1px solid white;
  border-bottom: 1px solid white; */
  margin: 20px 0px;
  @media screen and (min-width: 2000px){
      margin-top: 500px;
  }

  div {
    font-size: 16px;
    font-family: "Norse";
    text-transform: camelcase;
    white-space: nowrap;
    color: #fff;
    padding: 25px 0px;
    animation: ${AnimateRightLeft} 30s -15s linear infinite;
    will-change: transform;
    display: flex;
    align-items: center;
    /* &:nth-child(2) {
      color: #fff;
      animation: ${AnimateRightLeft2} 30s -15s linear infinite;
    } */

    @media (max-width: 1650px) {
      font-size: 4vw;
      padding: 12px 30px;
    }
    @media (max-width: 1250px) {
      font-size: 2vw;
    }
    @media (max-width: 1000px) {
      font-size: 3vw;
    }
    @media (max-width: 600px) {
      font-size: 10vw;
    }
  }

  @media (max-width: 600px) {
    border-top: 10px solid black;
    border-bottom: 10px solid black;
    margin: 12px 0;
  }
`;
const TikkerImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 70px;
`;

const S8_Ticker = () => {
  return (
    <SectionWrapper  style={{ overflow: "hidden" }}>
      <TickerPage>
        {/* <TickerPage> */}
        <TickerWrapper>
          <TickerScroller>
          <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div><div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
            <div>
              &nbsp;&nbsp;Fáfnir&nbsp;&nbsp; 
              <TikkerImage
                src="/images/tikker.png" 
              />
            </div>
          </TickerScroller>
        </TickerWrapper>
        {/* </TickerPage> */}
      </TickerPage>
    </SectionWrapper>
  );
};

export default S8_Ticker;
