import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {
  P,
  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";

const Background = styled.div`
  /* background: url("/images/bg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center; */
  @media screen and (max-width: 899px) {
    // padding: 100px 0 0 0;
  }
`;
const Button = styled.button`
 padding: 12px 30px;
    border: 1px solid #ffffff;
    background-color: #000000;
`;

const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
   /* background: url("/images/bg2.png"); */
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
const VideoMV = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const index = () => {
  return (
    <div>
      <SectionWrapper p="2rem 0 5rem 0" id="about">
      
      <Background>
      <VideoBK autoPlay loop muted>
        <source src="/images/dragonmainvid.mp4" type="video/mp4" />
      </VideoBK>
      <VideoMV autoPlay loop muted>
        <source src="/images/dragonmobile_x24.mp4" type="video/mp4" />
      </VideoMV>
        <Container maxWidth="xl">
          <Grid container>
          
            <Grid
              item
              xs={12}
              md={6}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              // data-aos-delay="500"
              // data-aos-offset="0"
              data-aos-duration="700"
            >
              <SectionSubHeading p="406px 0px 0px 0"></SectionSubHeading><br></br>
              <P maxWidth="80%" p="0px 0px 0px 0" style={{fontSize:'14px'}}>
              For thousands of years, Fáfnir has witnessed that Greed has led mankind, and now the degens of crypto, astray from humanity by corrupting their souls, leaving them always wanting and thirsting for more. It is time for Fáfnir to rise once again to restore humanity back to the degens of crypto.<br></br></P>
              <P maxWidth="80%" p="0px 0px 0px 0" style={{fontSize:'14px'}}>
                Fáfnir has created a token to unify all of mankind and to free us from our own selfish desires by stealing from us our most precious treasures that would otherwise destroy the hearts of man and descend us into chaos.
                </P>
                <Button>Read More</Button>
                {/* <Accordian /> */}
            </Grid>
            {/* <Grid xs={12} md={6}>
                <Grid
                  pc="center"
                  xs={12}
                  md={5}
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  // data-aos-delay="500"
                  // data-aos-offset="0"
                  data-aos-duration="1500"
                  style={{maxWidth: '100%'}}
                >
                   <Accordian />
                </Grid> */}
            
            {/* </Grid> */}
           
              
                <Grid
                  xs={12}
                  md={4}
                  // style={{paddingTop:'200px'}}
                >
                  {/* <VideoBK autoPlay loop muted>
                    <source src="/images/0001_7.mp4" type="video/mp4" />
                  </VideoBK> */}
                  
                </Grid>
              
            
          </Grid>
        </Container>
        </Background>
       
      </SectionWrapper>
    </div>
  );
};

export default index;
