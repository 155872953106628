import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import {

  SectionSubHeading,
  SectionWrapper,
} from "../../../styles";
import Accordian from "../../../components/Accordian";

const Background = styled.div`
  /* background: url("/images/bg2.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: 899px) {
    // padding: 100px 0 0 0;
  }
`;
const VideoBK = styled.video`
 background-size: cover;
  min-height: 100vh;
  display: flex;
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const VideoMV = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  width: 100%;
  // height: 100%;
  // position: absolute;
  z-index: -1;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const index = () => {
  return (
    <div>
      <SectionWrapper p="2rem 0 5rem 0" id="tokenomics">
      <Background>
      <VideoBK autoPlay loop muted>
        <source src="/images/Tokenomicssectionfirelogo.mp4" type="video/mp4" />
      </VideoBK>
        <Container maxWidth="xl">
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in"
              // data-aos-delay="500"
              // data-aos-offset="0"
              data-aos-duration="700"
            >
              <SectionSubHeading>Tokenomics</SectionSubHeading><br></br>
              {/* <P maxWidth="80%" p="0px 0px 0px 0" style={{fontSize:'16px'}}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magnn.
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magnn.
                </P> */}
                <Accordian />
            </Grid>
            {/* <Grid xs={12} md={6}>
                <Grid
                  pc="center"
                  xs={12}
                  md={5}
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in"
                  // data-aos-delay="500"
                  // data-aos-offset="0"
                  data-aos-duration="1500"
                  style={{maxWidth: '100%'}}
                >
                   <Accordian />
                </Grid> */}
            
            {/* </Grid> */}
           
              
                <Grid
                  xs={12}
                  md={6}
                >
                 <VideoMV autoPlay loop muted>
                    <source src="/images/tokenomicscropedrender_x264.mp4" type="video/mp4" />
                  </VideoMV>
                              
                </Grid>
              
            
          </Grid>
        </Container>
        </Background>
       
      </SectionWrapper>
    </div>
  );
};

export default index;