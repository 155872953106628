import { Container } from "@mui/material";
import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { SectionWrapper } from "../../styles";

const Style = styled.span`
  /* color: #3cc97b; */
`;

const Index = () => {
  const [expanded, setExpanded] = useState("false");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <SectionWrapper p="5rem 0 2rem 0" p599="5rem 0">
      <Container maxWidth="xl" data-aos="fade-up" data-aos-duration="9000">
        <Accordion
          style={{
            borderBottom: "1px solid #424242",
            borderTop: "1px solid #424242",
            backgroundColor: "transparent",
            color: "white",
          }}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
          //   expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
          //   aria-controls="panel1bh-content"
          //   id="panel1bh-header"
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "ROCK",

                padding: "10px 0",
                width: "100%",
                flexShrink: 0,
              }}
            >
              Ticker
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                width: "100%",
                flexShrink: 0,
                textAlign: "right",
                fontFamily: "Poppins-Regular",
              }}
            >
              Fáfnir
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            borderBottom: "1px solid #424242",
            backgroundColor: "transparent",
            color: "white",
          }}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "ROCK",

                padding: "10px 0",
                width: "100%",
                flexShrink: 0,
              }}
            >
              Supply
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                width: "100%",
                flexShrink: 0,
                textAlign: "right",
                fontFamily: "Poppins-Regular",
              }}
            >
              250,000,000
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            borderBottom: "1px solid #424242",
            backgroundColor: "transparent",
            color: "white",
          }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary>
            <Typography
              sx={{
                fontSize: "25px",
                fontFamily: "ROCK",

                width: "100%",
                flexShrink: 0,
                padding: "10px 0",
              }}
            >
              Slippage:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                width: "100%",
                flexShrink: 0,
                textAlign: "right",
                fontFamily: "Poppins-Regular",
              }}
            >
               5%-10%
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            borderBottom: "1px solid #424242",
            backgroundColor: "transparent",
            color: "white",
          }}
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary>
            <Typography
              sx={{
                fontSize: "25px",

                padding: "10px 0",
                fontFamily: "ROCK",
                width: "100%",
                flexShrink: 0,
              }}
            >
              Taxes
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                width: "100%",
                flexShrink: 0,
                textAlign: "right",
                fontFamily: "Poppins-Regular",
              }}
            >
              4%/5%
            </Typography>
          </AccordionDetails>
        </Accordion>
        
      </Container>
    </SectionWrapper>
  );
};

export default Index;
