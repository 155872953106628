import styled from "@emotion/styled";
import React from "react";
import { Container } from "@mui/material";
import {
  SectionSubHeading,
} from "../../../styles";
const SocialsDiv = styled.div`
  display: block;
  justify-content: left;
  padding: 50px 0 100px 0;

  align-items: center;
`;
const Footer = styled.div`
  /* background: url("/images/2064036.png");
  height: 100px;
  padding: 20px 0; */
  text-align: center;
  
  font-family: "Poppins-Regular";
`;
const Background = styled.div`
  // background: url("/images/2064036.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh
  display: block;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 899px) {
    padding: 100px 0 0 0;
  }
`;
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
   /* background: url("/images/bg2.png"); */
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 768px) {
    padding-top: 149px;
  }
`;
const SocialImage = styled.img` 
  width: 100%;
  height: 40px;
  max-width: 40px;
`
const index = () => {
  return (
    <div>
       <Background>
       <VideoBK autoPlay loop muted>
        <source src="/images/firebg_x264.mp4" type="video/mp4" />
      </VideoBK>
       <Container maxWidth="xl" sx={{minHeight: "calc(100vh - 40px)"}}>
         
       <SectionSubHeading>Join The Community</SectionSubHeading><br></br>
      <SocialsDiv data-aos="fade-up" data-aos-duration="1000">
        
          <a href="111">
            <SocialImage
              src="/images/Tele.png"
            />
            </a> 
             &nbsp;&nbsp;&nbsp;
            <a href="222"><SocialImage
              src="/images/Twi.png"
            />
            </a>
             &nbsp;&nbsp;&nbsp;
           <a href="333">
             <SocialImage
              src="/images/Me.png"
            />
            </a>
        
      </SocialsDiv>
     
      </Container>
      <Footer>Copyright © 2022 Fafnir</Footer>
      </Background>
      
     
      
    </div>
  );
};

export default index;
